import React from "react";
import SliderLogos3 from "../Slider/SliderLogos3";
import { StaticImage } from "gatsby-plugin-image";

function TrustedBy() {
  return (
    <section className="mb-10 md:mb-20">
      <div className="mx-auto mb-10 text-center font-heading font-semibold uppercase text-[#CCCCCC]">
        Trusted By
      </div>
      <SliderLogos3>
        <div className="mx-20 !flex h-[40px] max-w-[148px] flex-col items-center justify-center md:max-w-[126px]">
          <StaticImage
            src="../../images/0.0 Repeating Modules/LogoSlider/Berkshire Hathaway.png"
            loading="lazy"
            width={240}
            height={64}
            className="object-contain"
          />
        </div>
        <div className="mx-20 !flex h-[40px] max-w-[148px] flex-col items-center justify-center md:max-w-[126px]">
          <StaticImage
            src="../../images/0.0 Repeating Modules/LogoSlider/BigBlock.png"
            loading="lazy"
            width={328}
            height={64}
            className="object-contain"
          />
        </div>
        <div className="mx-20 !flex h-[40px] max-w-[148px] flex-col items-center justify-center md:max-w-[126px]">
          <StaticImage
            src="../../images/0.0 Repeating Modules/LogoSlider/Century 21.png"
            loading="lazy"
            width={464}
            height={55}
            className="object-contain"
          />
        </div>
        <div className="mx-20 !flex h-[40px] max-w-[148px] flex-col items-center justify-center md:max-w-[126px]">
          <StaticImage
            src="../../images/0.0 Repeating Modules/LogoSlider/Compass.png"
            loading="lazy"
            width={364}
            height={51}
            className="object-contain"
          />
        </div>
        <div className="mx-20 !flex h-[40px] max-w-[148px] flex-col items-center justify-center md:max-w-[126px]">
          <StaticImage
            src="../../images/0.0 Repeating Modules/LogoSlider/DouglasElliman.png"
            loading="lazy"
            width={464}
            height={68}
            className="object-contain"
          />
        </div>
        <div className="mx-20 !flex h-[40px] max-w-[148px] flex-col items-center justify-center md:max-w-[126px]">
          <StaticImage
            src="../../images/0.0 Repeating Modules/LogoSlider/KellerWilliams.png"
            loading="lazy"
            width={520}
            height={52}
            className="object-contain"
          />
        </div>
        <div className="mx-20 !flex h-[40px] max-w-[148px] flex-col items-center justify-center md:max-w-[126px]">
          <StaticImage
            src="../../images/0.0 Repeating Modules/LogoSlider/RedFin.png"
            loading="lazy"
            width={252}
            height={64}
            className="object-contain"
          />
        </div>
        <div className="mx-20 !flex h-[40px] max-w-[148px] flex-col items-center justify-center md:max-w-[126px]">
          <StaticImage
            src="../../images/0.0 Repeating Modules/LogoSlider/Remax.png"
            loading="lazy"
            width={300}
            height={56}
            className="object-contain"
          />
        </div>
        <div className="mx-20 !flex h-[40px] max-w-[148px] flex-col items-center justify-center md:max-w-[126px]">
          <StaticImage
            src="../../images/0.0 Repeating Modules/LogoSlider/Sotheby_s.png"
            loading="lazy"
            width={306}
            height={72}
            className="object-contain"
          />
        </div>
      </SliderLogos3>
    </section>
  );
}

export default TrustedBy;
