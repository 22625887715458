import React, { useState } from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";
import Layout from "../../components/Layout";
import SearchEngineOptimization from "../../components/SEO";
import CallToAction from "../../components/Repeating/CTA";
import ButtonSolid from "../../components/Button/ButtonSolid";
import TrustedBy from "../../components/Repeating/TrustedBy";

function encode(data) {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }
  return formData;
}

const StyledForm = styled.div`
  [multiple],
  [type="email"],
  [type="tel"],
  [type="text"],
  select,
  textarea {
    ${tw`text-sm w-full text-gray-600 bg-white px-4 py-2.5 border border-solid border-gray-300 rounded-sm focus:border-transparent focus:outline-none focus:ring-3 focus:ring-primary-700 focus:ring-opacity-30 transition-colors duration-300 ease-linear`}
  }
`;

const Page = ({ data }) => {
  const [state, setState] = useState({});

  const handleChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(
        () =>
          (document.getElementById("contact-form-ajax-response").innerHTML =
            "Thank you for for submission! We will get in touch with you shortly."),
        form.remove(),
        (window.dataLayer = window.dataLayer || []),
        window.dataLayer.push({
          event: "contactFormSubmission",
        })
      )
      .catch((error) => alert(error));
  };

  const HeadingTag = "h2";
  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Business Intelligence Platform | Data Analytics & Visualization | Realsynch"
        description="Transform your data into actionable insights with Realsynch's decision intelligence platform. Unlock the power of predictive analytics and augmented analytics for smarter business decisions."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className={"my-[220px] md:mb-32"}>
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-1 md:order-1">
              <HeadingTag>Realsynch is Free to Try</HeadingTag>
              <p>
                See what it’s like to have the equivalent of your very own
                business advisor helping you shape the future of your business.
              </p>
              <ul className="mb-10 flex flex-col gap-y-2">
                <li className="flex gap-x-[11px]">
                  <StaticImage
                    src="../../images/1.0 Homepage/bullet-check.svg"
                    loading="lazy"
                    width={25}
                    height={25}
                  />
                  Instantly review business data in one place
                </li>
                <li className="flex gap-x-[11px]">
                  {" "}
                  <StaticImage
                    src="../../images/1.0 Homepage/bullet-check.svg"
                    loading="lazy"
                    width={25}
                    height={25}
                  />
                  Get the analysis you need to make informed decisions
                </li>
                <li className="flex gap-x-[11px]">
                  {" "}
                  <StaticImage
                    src="../../images/1.0 Homepage/bullet-check.svg"
                    loading="lazy"
                    width={25}
                    height={25}
                  />
                  Accelerate the growth of your business
                </li>
              </ul>
            </div>
            <div className="order-2 md:order-2">
              <form
                name="Contact"
                className="rounded-[16px] border-[1px] border-[#CCCCCC] p-10"
                method="post"
                action=""
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="Contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </div>
                <div className="mb-5">
                  <label
                    className="mb-1 block font-body text-sm font-bold text-gray-800"
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    name="email"
                    onChange={handleChange}
                    required={true}
                    className="w-full rounded-[2px] border-[1px] border-[#808080]"
                  />
                </div>
                <div className="mb-5">
                  <label
                    className="mb-1 block font-body text-sm font-bold text-gray-800"
                    htmlFor="name"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    onChange={handleChange}
                    required={true}
                    className="w-full rounded-[2px] border-[1px] border-[#808080]"
                  />
                </div>
                <div className="mb-5">
                  <label
                    className="mb-1 block font-body text-sm font-bold text-gray-800"
                    htmlFor="phone"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    onChange={handleChange}
                    required={true}
                    className="w-full rounded-[2px] border-[1px] border-[#808080]"
                  />
                </div>
                <div className="mb-5">
                  <label
                    className="mb-1 block font-body text-sm font-bold text-gray-800"
                    htmlFor="companySize"
                  >
                    Company Size
                  </label>
                  <input
                    type="number"
                    name="companySize"
                    onChange={handleChange}
                    required={true}
                    className="w-full rounded-[2px] border-[1px] border-[#808080]"
                  />
                </div>
                <div className="mb-5">
                  <label
                    className="mb-1 block font-body text-sm font-bold text-gray-800"
                    htmlFor="companyName"
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    name="companyName"
                    onChange={handleChange}
                    required={true}
                    className="w-full rounded-[2px] border-[1px] border-[#808080]"
                  />
                </div>

                <ButtonSolid type="submit" text="Start Free Trial" />
              </form>
            </div>
          </div>
        </div>
      </section>
      <TrustedBy />

      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    optimizeYourPipeline: file(
      relativePath: { eq: "3.0 Features/2. Optimize Your Pipeline.svg" }
    ) {
      publicURL
    }
    createEfficiency: file(
      relativePath: { eq: "3.0 Features/4.0 Create Efficiency.svg" }
    ) {
      publicURL
    }
    integrationAutomationReports: file(
      relativePath: {
        eq: "3.0 Features/5.0 Integration _ Automation Reports.svg"
      }
    ) {
      publicURL
    }
    success1: file(
      relativePath: { eq: "3.0 Features/3.0 Free Strategy Call.svg" }
    ) {
      publicURL
    }
    success2: file(
      relativePath: { eq: "3.0 Features/3.1 Dedicated Support Agent.svg" }
    ) {
      publicURL
    }
    success3: file(
      relativePath: { eq: "3.0 Features/3.2 Easy Ways To Connect With Us.svg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
